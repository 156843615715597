$(function() {
	//是否开启side收缩0:关闭，1：开启
	fold(1);
	
	calcSideH();
	$(window).resize(function() {
		calcSideH();
	});
	//去除side>div下最后一个a的border
	$(".side li div").each(function() {
		$(this).children("a").last().css("border", "none")
	});
	//将图像替换成背景
	imgToBg($(".side li h2 span"));
	//手凤琴
	$(".side h2").click(function() {
		var status = $(this).next("div").css("display");
		if(status == "none") {
			$(this).next("div").slideDown();
			$(this).addClass("on");
		} else {
			$(this).next("div").slideUp();
			$(this).removeClass("on");
		}
	});
	$(".side h2").each(function() {
		var status = $(this).hasClass("on");
		if(status) {
			$(this).next("div").show();
		} else {
			$(this).next("div").hide();
		}
	});
	//table tr hover
	$(".table-response tbody tr").on({
		'mouseover': function() {
			$(this).find("td").css("background", "#e8eff5");
		},
		'mouseout': function() {
			$(this).find("td").css("background", "");
		}
	});

	//筛选列表
	$(".choiceList dl").each(function() {
		if(!$(this).hasClass("s1")) {
			var ddh = $(this).find("dd").height();
			if(ddh > 32) {
				$(this).find(".more").show();
			} else {
				$(this).find(".more").hide();
			}
		}
	});
	$(".choiceList .more").click(function() {
		var dlh = $(this).closest("dl").height();
		if(dlh == 32) {
			$(this).closest("dl").css("height", "100%");
			$(this).addClass("up");
		} else {
			$(this).closest("dl").css("height", '')
			$(this).removeClass("up");
		}
	});
	$(".choiceList a").click(function() {
		var status = $(this).hasClass("active");
		if(status) {
			$(this).removeClass("active")
		} else {
			$(this).addClass("active")
		}
	});
	//表格滚动
	table_scroll();


	//side伸缩
	var sideW = $(".side").width();
	var onoff = 1;
	var bodyClass = $("body").attr("class");
	$(".side .arrLR").css("left", sideW).click(function() {
		if(onoff) {
			$("body").removeClass(bodyClass);
			$(this).addClass("r").animate({
				"left": 0
			}, 300);
			$(".side").animate({
				"margin-left": -sideW
			}, 300);
			$(".main").animate({
				"margin-left": 30
			}, {
				duration: 300,
				complete: function() {
					table_scroll();
				}
			})
		} else {
			setTimeout(function() {
				$("body").addClass(bodyClass);
			}, 300)
			$(this).removeClass("r").animate({
				"left": sideW
			}, 300);;
			$(".side").animate({
				"margin-left": 0
			}, 300);
			$(".main").animate({
				"margin-left": 30 + sideW
			}, {
				duration: 300,
				complete: function() {
					table_scroll();
				}
			})
		}
		onoff = !onoff;
	});

	

})

var tableOT, tableOL, table_head_width;
var result = [];

function getOffset(obj) {
	result = []; //[]
	tableOT = $(obj).offset().top;
	tableOL = $(obj).offset().left;
	result.push(tableOT);
	result.push(tableOL);
	return result;
}

function table_scroll() {
	if($("body").find("#table_scroll").length) {
		getOffset('#table_scroll');
	}
	tableOT = result[0];
	tableOL = result[1];
	setTdWidth();
	$(window).scroll(function() {
		var st = $(window).scrollTop()
		table_head_width = $('.thead_2').width();
		if(st >= tableOT + 30) {
			$('.table_fixed').css({
				'display': 'block',
				'width': table_head_width,
				'left': tableOL
			})
		} else {
			$('.table_fixed').css({
				'display': 'none',
				'width': table_head_width,
				'left': tableOL
			})
		}

	})
}

function setTdWidth() {
	var thead_1_td_width = $('.thead_1 td');
	var thead_2_td_width = $('.thead_2 td');
	for(var i = 0; i < thead_2_td_width.length; i++) {
		thead_1_td_width.eq(i).css('width', thead_2_td_width.eq(i).width());
	}
	table_head_width = $('.thead_2').width();
	$(".table_fixed").animate({
		"left": result[1],
		"width": table_head_width
	}, 300)
}

function calcSideH() {
	$(".side,.main").css("min-height", '')
	var winW = $(window).width();
	var winH = $(window).height();
	var headH = $(".head").height();
	var H = winH - headH;
	var mainH = $(".main").height();
	if(H > mainH) {
		$(".side").css("min-height", H);
		$(".main").css("min-height", H - 71)
	} else {
		$(".side").css("min-height", mainH + 71);
	}
};

function imgToBg(obj) {
	obj.each(function() {
		var imgSrc = $(this).find("img").attr("src");
		$(this).find("img").hide();
		$(this).css("background", "url(" + imgSrc + ") no-repeat center center")
	})
}

function fold(status) {
	if(status) {
		var str = '<span class="arrLR l"></span>';
		$(".side").append(str)
	} else {
		$(".side").find(".arrLR").remove();
	}
}